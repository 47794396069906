import React, { useState, useContext } from "react"
import { AuthContext } from "@/lib/auth"
import { Button, Card, Form } from "react-bootstrap"
import { useNavigate } from "react-router-dom"
import { CustomModal } from "@/components"

export default () => {
  const [inputAddressErrorCheck, setInputAddressErrorCheck] = useState<boolean>(false)
  const auth = useContext(AuthContext)
  const [username, setUsername] = useState<string>("")
  const [modalShow, setModalShow] = useState<boolean>(false)
  const navigate = useNavigate()

  const handleUserNameChange = (e: { target: { value: React.SetStateAction<string> } }) => setUsername(e.target.value)

  const handleForgotPassword = async () => {
    try {
      const data = await auth.forgotPassword(username)
      setModalShow(true)
      setInputAddressErrorCheck(true)
      console.log(data)
    } catch (err: any) {
      setModalShow(true)
      setInputAddressErrorCheck(false)
      console.log(err)
    }
  }

  return (
    <>
      <CustomModal
        title={!inputAddressErrorCheck ? "エラー" : "認証コード送信完了"}
        show={modalShow}
        message={
          !inputAddressErrorCheck
            ? "メールアドレスをご確認ください。"
            : "ご登録のアドレスに認証コードを送信しました。次ページで受信したコードと新しいパスワードを入力してください。"
        }
        size="xl"
        onHide={() => {
          setModalShow(false)
          {
            inputAddressErrorCheck && navigate("/auth/password-reset")
          }
        }}
      />
      <Card>
        <Card.Header>
          <img src="/img/logo.png" />
        </Card.Header>
        <Card.Body className="m-3">
          <h6 className="mb-4 fw-bold">パスワードをお忘れの方は、ご登録のメールアドレスを入力してください。</h6>
          <Form>
            <Form.Group>
              <Form.Label className="fw-bold mb-2">メールアドレス</Form.Label>
              <Form.Control
                type="text"
                name="username"
                placeholder=""
                onChange={handleUserNameChange}
                className="w-20 mb-1"
              />
            </Form.Group>
            <Form.Group>
              <Button className="btn-primary w-100 btn-block mt-2 mb-4" onClick={handleForgotPassword}>
                <span>送信</span>
              </Button>
            </Form.Group>
          </Form>
        </Card.Body>
      </Card>
    </>
  )
}
