import React from "react"
import { ShowThumbnailMovie } from ".."
import { Link } from "react-router-dom"
import { useRecentMovie } from "@/hooks/useRecentMovie"
import { Spinner } from "react-bootstrap"

export const ShowRecentMovie = () => {
  const recentMovieQuery = useRecentMovie()
  const { data: recentMovie, isLoading } = recentMovieQuery

  // if (!recentmovie) return <>No data</>

  return (
    <>
      {isLoading && (
        <div className="w-100 text-center">
          <Spinner animation="border" variant="info" />
        </div>
      )}
      {recentMovie && (
        <>
          <h5 className="sub-title">
            新着動画
            <Link to={`/movie/recent-list`} className="btn btn-primary ms-3 ">
              もっと見る
            </Link>
          </h5>
          <ShowThumbnailMovie currentMovieId="" movie={recentMovie} />
        </>
      )}
      {!recentMovie && <main>新着動画はありません</main>}
    </>
  )
}
