import { useQuery } from "@tanstack/react-query"
import * as apigw from "@/lib/apigw"
import { IMovie } from "@/features/categoryMovie"

export const useRecentMovie = (opts?: any) => {
  return useQuery({
    queryKey: ["recentmovie"],
    queryFn: async () => {
      const url = "/user/get-recent-movie"
      const { data } = await apigw.request<IMovie>(url, opts)
      return data
    },
  })
}
