import React from "react"
import { BsPlayFill } from "react-icons/bs"
import { Link, useParams } from "react-router-dom"

type BreadCrumbInfo = {
  allCategories?: Breadcrumb[]
  currentCategory?: Breadcrumb
  count?: number
}

type Breadcrumb = { name?: string; id?: string; parentId?: string; link: string }

export const ShowBreadCrumbTree = (props: BreadCrumbInfo) => {
  const { allCategories, currentCategory, count } = props
  const depthCount = (count || 0) + 1
  const params = useParams<{ id: string }>()

  return (
    <>
      {params.id === currentCategory?.id ? (
        <span>{currentCategory?.name}</span>
      ) : (
        <Link to={currentCategory?.link || ""}>{currentCategory?.name}</Link>
      )}

      {allCategories
        ?.filter((childCategory: Breadcrumb) => {
          return childCategory.parentId === currentCategory?.id
        })
        ?.map((childCategory: Breadcrumb, index: number) => {
          return (
            <div
              key={index}
              className="d-inline-block"
              style={{ marginLeft: depthCount * 5 + "px", marginRight: "5px" }}
            >
              <BsPlayFill className="ms-4 text-dark" />
              <ShowBreadCrumbTree allCategories={allCategories} currentCategory={childCategory} count={depthCount} />
            </div>
          )
        })}
    </>
  )
}
