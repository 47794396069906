import { useQuery } from "@tanstack/react-query"
import * as apigw from "@/lib/apigw"
import { ICategory } from "@/features/categoryMovie"

export const useCategory = (id?: string, opts?: any) => {
  return useQuery({
    queryKey: ["category", id],
    queryFn: async () => {
      const url = "/user/get-category"
      const { data } = await apigw.request<ICategory>(url, { id }, opts)
      return data
    },
  })
}
