import React from "react"
import { useParams } from "react-router-dom"
import { ShowPlayMovie } from "@/features/categoryMovie"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"

type Params = { id: string; categoryStatus: string }

export const ShowMovie = () => {
  const params = useParams<Params>()
  const { id } = params

  return (
    <>
      <Container fluid>
        {/*        <Row>
          <Breadcrumb categoryId={params.id} />
        </Row>
  */}
        <Row>{id && <ShowPlayMovie id={id} />}</Row>
      </Container>
    </>
  )
}
