import React from "react"
import { Link } from "react-router-dom"
import Container from "react-bootstrap/Container"
import { ICategory } from "@/features/categoryMovie"
import { useCategories } from "@/hooks/useCategories"

export const HeaderMenu = () => {
  const topCategoriesQuery = useCategories()
  const { data: topCategories } = topCategoriesQuery

  return (
    <Container fluid className="header-menu mt-1">
      {topCategories?.pages.map((page) =>
        page.map((topCategory: ICategory, index: number) => {
          return (
            <div
              key={`top-category-${topCategory.id}-${index}`}
              className="mt-2 mx-1 d-inline-block float-left display-flex"
            >
              <Link to={`/category/${topCategory.id}/`} className="btn btn-secondary rounded-pill px-3 btn-sm">
                {topCategory.name}
              </Link>
            </div>
          )
        })
      )}
    </Container>
  )
}
