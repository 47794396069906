import React, { useEffect, useState } from "react"
import Auth from "@aws-amplify/auth"
import { Card, Form, Button } from "react-bootstrap"
import { useNavigate } from "react-router-dom"
import { CustomModal } from "@/components"

export default () => {
  const [errorMessage, setErrorMessage] = useState<any>("")
  const [inputErrorCheck, setInputErrorCheck] = useState<boolean>(false)
  const [username, setUsername] = useState("")
  const [verificationCode, setverificationCode] = useState("")
  const [newPassword, setNewPassword] = useState("")
  const [modalShow, setModalShow] = useState(false)
  const navigate = useNavigate()

  useEffect(() => {
    errorMessage !== "" && setModalShow(true)
  }, [errorMessage])

  useEffect(() => {
    !modalShow && setErrorMessage("")
  }, [modalShow])

  const handleUsernameChange = (e: { target: { value: React.SetStateAction<string> } }) => {
    setUsername(e.target.value)
  }

  const handleVerificationCodeChange = (e: { target: { value: React.SetStateAction<string> } }) => {
    setverificationCode(e.target.value)
  }

  const handleNewPasswordChange = (e: { target: { value: React.SetStateAction<string> } }) => {
    setNewPassword(e.target.value)
  }

  const handlePasswordReset = async () => {
    try {
      await Auth.forgotPasswordSubmit(username, verificationCode, newPassword)
      setModalShow(true)
      setInputErrorCheck(true)
    } catch (error) {
      setModalShow(true)
      setInputErrorCheck(false)
    }
  }

  return (
    <>
      <CustomModal
        title={!inputErrorCheck ? "エラー" : "パスワード変更完了"}
        show={modalShow}
        message={!inputErrorCheck ? "入力内容をご確認ください。" : "パスワードを変更しました。"}
        size="xl"
        onHide={() => {
          setModalShow(false)
          {
            inputErrorCheck && navigate("/auth/signin")
          }
        }}
      />
      <Card>
        <Card.Header>
          <img src="/img/logo.png" />
        </Card.Header>
        <Card.Body>
          <Form>
            <Form.Group>
              <Form.Label className="fw-bold mb-2">メールアドレス</Form.Label>
              <Form.Control type="text" value={username} onChange={handleUsernameChange} required />
            </Form.Group>
            <Form.Group>
              <Form.Label className="fw-bold mt-2 mb-2">確認コード</Form.Label>
              <Form.Control type="text" value={verificationCode} onChange={handleVerificationCodeChange} required />
            </Form.Group>
            <Form.Group>
              <Form.Label className="fw-bold mt-2 mb-2">新しいパスワード</Form.Label>
              <Form.Control type="password" value={newPassword} onChange={handleNewPasswordChange} required />
            </Form.Group>
            <Form.Group>
              <Button className="w-100 btn-block mt-4 mb-4" onClick={handlePasswordReset}>
                パスワードリセット
              </Button>
            </Form.Group>
          </Form>
        </Card.Body>
      </Card>
    </>
  )
}
