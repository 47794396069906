import * as apigw from "@/lib/apigw"

type GetBreadcrumbParams = {
  categoryId?: string
  opts?: any
}

export const getBreadcrumbDb = async (params: GetBreadcrumbParams): Promise<any> => {
  const res = await apigw.request("/user/get-breadcrumb", { ...params }, params.opts)
  return res
}
