import React from "react"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"

export const Footer = () => {
  return (
    <>
      <Container fluid className="footer">
        <Row>
          <Col>&copy; Surf Nation Japan ALL Rights Reserved.</Col>
        </Row>
      </Container>
    </>
  )
}
