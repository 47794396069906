import React from "react"
import Spinner from "react-bootstrap/Spinner"
import { ICategory, ShowChildCategoryMovies } from ".."

import { useAllCategories } from "@/hooks/"
type Props = { parentId?: string }
// type GetCategoriesProps = { init?: boolean; nextId?: string }

export const ShowAllChildCategories = ({ parentId }: Props) => {
  const childCategoriesQuery = useAllCategories(parentId, 5)
  const { data: childCategories, isLoading } = childCategoriesQuery

  return (
    <>
      {isLoading && (
        <div className="w-100 text-center">
          <Spinner animation="border" variant="info" />
        </div>
      )}
      {!isLoading && childCategories && (
        <>
          {childCategories?.map((childCategory: ICategory) => {
            return (
              <div key={`category-${childCategory.id}`}>
                <ShowChildCategoryMovies category={childCategory} />
              </div>
            )
          })}
          {/* {isNextLoaded && (
            <div className="w-100 text-center">
              <Spinner animation="border" variant="info" />
            </div>
          )} */}
        </>
      )}
    </>
  )
}
