import React from "react"
import { useParams } from "react-router-dom"
import { Breadcrumb } from "@/components"
import { ShowCategoryMovies, ShowAllChildCategories } from "@/features/categoryMovie"
import Container from "react-bootstrap/Container"

export const ShowCategory = () => {
  const params = useParams<{ id: string }>()

  return (
    <>
      <Container fluid>
        <Breadcrumb categoryId={params.id} />
        <ShowCategoryMovies categoryId={params.id} />
        <ShowAllChildCategories parentId={params.id} />
      </Container>
    </>
  )
}
