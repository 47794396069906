import React from "react"
import { Button, Navbar } from "react-bootstrap"

export const AboutLink = () => {
  return (
    <>
      <Navbar.Text>
        <Button className="me-lg-3" variant="light" target="blank" rel="noopener noreferrer" href="/about">
          初めてのお客様はこちら
        </Button>
      </Navbar.Text>
    </>
  )
}
