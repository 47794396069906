import React from "react"
import { SearchMovieList } from "@/features/categoryMovie"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"

export const SearchListMovie = () => {
  return (
    <>
      <Container fluid>
        <Row>
          <SearchMovieList />
        </Row>
      </Container>
    </>
  )
}
