import React from "react"
import { RecentMovieList } from "@/features/categoryMovie"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"

export const RecentListMovie = () => {
  return (
    <>
      <Container fluid>
        <Row>
          <RecentMovieList />
        </Row>
      </Container>
    </>
  )
}
