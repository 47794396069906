type Props = {
  categoryStatus?: string
  userLicenseId?: string
  movieLicenses?: string[]
}

export const checkMovieAccess = (params: Props) => {
  let result = false

  // ライセンスユーザ限定
  if (params.categoryStatus && +params.categoryStatus === 1) {
    if (params.userLicenseId && params.movieLicenses?.includes(params.userLicenseId)) {
      result = true
    }
    // ゲスト公開
  } else if (params.categoryStatus && +params.categoryStatus === 2) {
    result = true
  }
  return result
}
