import React, { useContext } from "react"
import { AuthContext } from "@/lib/auth"
import { IMovie, SelectThumbnail } from ".."
import { Link } from "react-router-dom"
import { BsFillLockFill, BsPlayBtnFill } from "react-icons/bs/"
import { checkMovieAccess } from "@/features/categoryMovie"

type Props = { movie: IMovie; currentMovieId: string | undefined; playerPage?: boolean }

export const ShowThumbnailMovie = ({ movie, currentMovieId, playerPage }: Props) => {
  const postedDate = movie?.updateDate?.split(" ") || ""
  const auth = useContext(AuthContext)

  return (
    <>
      {/* ライセンスが必要な動画サムネ */}
      {!checkMovieAccess({
        categoryStatus: movie?.categoryStatus,
        userLicenseId: auth.currentUser?.licenseId,
        movieLicenses: movie.licenseIds,
      }) &&
        movie?.id !== currentMovieId && (
          <div className="thumbnail-licence">
            <div className="licence">
              <div className="licence-lock-wrap">
                <BsFillLockFill size={40} className="lock-icon mb-3" />
                <br />
                ライセンスが必要です
              </div>
            </div>
            <div className="thumbnail-content-wrap">
              <Link to={`/movie/${movie.id}/`} className="thumbnail-content">
                <SelectThumbnail movie={movie} />
              </Link>
            </div>
          </div>
        )}
      {/* ライセンスがあり、再生中の動画サムネ */}
      {checkMovieAccess({
        categoryStatus: movie?.categoryStatus,
        userLicenseId: auth.currentUser?.licenseId,
        movieLicenses: movie.licenseIds,
      }) &&
        playerPage &&
        movie?.id === currentMovieId &&
        movie?.categoryStatus &&
        [1, 2].includes(+movie?.categoryStatus) && (
          <div className="thumbnail-licence">
            <div className="licence">
              <div className="licence-play-wrap">
                <BsPlayBtnFill size={40} className="play-icon mb-3" />
                <br />
                再生中
              </div>
            </div>
            <div className="thumbnail-content-wrap">
              <Link to={`/movie/${movie.id}/`} className="thumbnail-content">
                <SelectThumbnail movie={movie} />
              </Link>
            </div>
          </div>
        )}

      {/* ライセンスはあり、動画再生ページ以外のサムネ */}
      {!playerPage &&
        checkMovieAccess({
          categoryStatus: movie?.categoryStatus,
          userLicenseId: auth.currentUser?.licenseId,
          movieLicenses: movie.licenseIds,
        }) && (
          <>
            <Link to={`/movie/${movie.id}/`} className="thumbnail-content">
              <SelectThumbnail movie={movie} />
            </Link>
          </>
        )}

      {/* ライセンスはあり、動画再生ページのサムネ */}
      {playerPage &&
        checkMovieAccess({
          categoryStatus: movie?.categoryStatus,
          userLicenseId: auth.currentUser?.licenseId,
          movieLicenses: movie.licenseIds,
        }) &&
        movie?.id !== currentMovieId && (
          <>
            <Link to={`/movie/${movie.id}/`} className="thumbnail-content">
              <SelectThumbnail movie={movie} />
            </Link>
          </>
        )}

      <div className="my-1">
        {movie?.categoryStatus && +movie?.categoryStatus === 2 && (
          <>
            <span className="badge bg-secondary h6 me-2">ゲスト公開</span>
          </>
        )}

        {movie?.id !== currentMovieId && <>{movie?.name}</>}
      </div>

      {/* 動画再生ページのサムネの動画タイトル */}
      {playerPage &&
        checkMovieAccess({
          categoryStatus: movie?.categoryStatus,
          userLicenseId: auth.currentUser?.licenseId,
          movieLicenses: movie.licenseIds,
        }) &&
        movie?.id === currentMovieId && (
          <div className="my-1">{movie?.id === currentMovieId && <>{movie?.name}</>}</div>
        )}

      {/* 動画再生ページのサムネの動画説明(再生中以外の動画) */}

      {movie.id !== currentMovieId && (
        <div className="small text-secondary">
          <span className="me-1"> {postedDate[0]}</span>投稿・<span className="me-1">{movie?.playCount}</span>回視聴
          <div>{movie?.playProgressRate && "視聴済み"}</div>
        </div>
      )}

      {/* 動画再生ページのサムネの動画説明(再生中動画) */}
      {playerPage &&
        checkMovieAccess({
          categoryStatus: movie?.categoryStatus,
          userLicenseId: auth.currentUser?.licenseId,
          movieLicenses: movie.licenseIds,
        }) &&
        movie?.id === currentMovieId && (
          <div className="small text-secondary">
            <span className="me-1"> {postedDate[0]}</span>投稿・<span className="me-1">{movie?.playCount}</span>回視聴
            <div>{movie?.playProgressRate && "視聴済み"}</div>
          </div>
        )}
    </>
  )
}
