import { useInfiniteQuery } from "@tanstack/react-query"
import * as apigw from "@/lib/apigw"
import { IMovie } from "@/features/categoryMovie"

export const useRecentMovieList = (nextId?: string, opts?: any) => {
  return useInfiniteQuery(
    ["recent-movie-list", nextId],
    async ({ pageParam = undefined }) => {
      const url = "/user/get-recent-movie-list"
      const { data } = await apigw.request<IMovie[]>(url, { nextId: pageParam }, opts)
      return data
    },
    {
      getNextPageParam: (lastPage) => lastPage[lastPage.length - 1]?.nextId,
    }
  )
}
