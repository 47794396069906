import React from "react"
import { Button, Modal } from "react-bootstrap"

export const CustomModal = (props: any) => {
  return (
    <Modal {...props} aria-labelledby="contained-modal-title-vcenter" centered>
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">{props.title || "エラーです"}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p className="">{props.message}</p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={props.onHide}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
