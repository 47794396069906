import React from "react"
import { ShowChildCategories, ShowRecentMovie } from "@/features/categoryMovie"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import { ShowNotices } from "@/features/notice"

export const Home = () => {
  return (
    <>
      <Row className="mb-4">
        <Col>
          <div className="contents-box mb-0 h-100">
            <ShowRecentMovie />
          </div>
        </Col>
        <Col md={8}>
          <div className="contents-box mb-0 h-100">
            <ShowNotices />
          </div>
        </Col>
      </Row>
      <Row>
        <ShowChildCategories parentId={""} />
      </Row>
    </>
  )
}
