import React from "react"
import { Header, Footer, HeaderMenu } from "@/components"

function App({ Component, ...rest }: any) {
  let route = <React.Fragment>{Component}</React.Fragment>

  if (
    rest.contents &&
    rest.id != "auth-signin" &&
    rest.id != "forgot-password" &&
    rest.id != "auth-passowrd-reset" &&
    rest.id != "about"
  ) {
    route = (
      <>
        <Header />
        <HeaderMenu />
        <div id="contents">{route}</div>
        <Footer />
      </>
    )
  } else if (rest.contents && rest.id === "about") {
    route = (
      <>
        <Header />
        <div id="contents">{route}</div>
        <Footer />
      </>
    )
  }

  if (rest.id) {
    route = <div id={rest.id}>{route}</div>
  }

  route = <React.Fragment>{route}</React.Fragment>

  return route
}

export default App
