import React, { useContext, useEffect, useState } from "react"
import { Container, Row, Col, Button, Navbar, Nav } from "react-bootstrap"
import { AuthContext } from "../lib/auth"
import Logo from "@/img/logo.png"
import { BsFillPersonFill } from "react-icons/bs/"
import { IUser } from "@/features/user"
import { ShowSearchMovie } from "@/features/categoryMovie"
import { AboutLink, AddLineFriendsLink, CorporateSiteLink, SignupLink } from "@/components"

export const Header = () => {
  const [currentUser, setCurrentUser] = useState<IUser | null>(null)
  const auth = useContext(AuthContext)

  useEffect(() => {
    setCurrentUser(auth.currentUser)
  }, [auth])

  const userEmail = currentUser?.attributes?.email
  // const colSize = auth.currentUser ? 6 : 2

  return (
    <>
      <Navbar collapseOnSelect expand="lg" variant="dark" className="header">
        <Container fluid>
          <Navbar.Brand href="/" className="flex-sm-grow-1">
            <img src={Logo} alt="Surf Nation Japan" className="header-logo" />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="navbar-responsive" />
          <Navbar.Collapse id="navbar-responsive" className="justify-content-end">
            <Nav className="align-items-center m-2 m-lg-0">
              <AboutLink />
              <AddLineFriendsLink />
              <CorporateSiteLink />
              {!auth.currentUser && <SignupLink />}

              <Navbar.Text className="text-white">
                <BsFillPersonFill size={24} className="me-2 me-lg-3" />
                {userEmail ? userEmail : null}
              </Navbar.Text>

              {currentUser?.licenseName && (
                <Navbar.Text className="ms-lg-3 me-lg-3 text-white">{currentUser?.licenseName}</Navbar.Text>
              )}
              {ShowSignBtn({
                auth,
              })}
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <Container>
        <Row>
          <Col>
            <ShowSearchMovie />
          </Col>
        </Row>
      </Container>
    </>
  )
}

const ShowSignBtn = (props: any) => {
  interface SignButton {
    path?: string
    label?: string
  }
  let button: SignButton = {}
  if (props.auth.currentUser) {
    button = {
      path: "/auth/signout",
      label: "LOGOUT",
    }
  } else {
    button = {
      path: "/auth/signin",
      label: "LOGIN",
    }
  }
  return (
    <>
      <Navbar.Text>
        <Button variant="light" href={button.path}>
          {button.label}
        </Button>
      </Navbar.Text>
    </>
  )
}
