import React, { useState } from "react"
import { AuthProvider } from "./lib/auth"
import { QueryClient, QueryClientProvider } from "@tanstack/react-query"
import Pages from "./route"

function App() {
  const [isLoaded, setIsLoaded] = useState(false)
  const queryClient = new QueryClient()

  return (
    <QueryClientProvider client={queryClient}>
      <AuthProvider onLoaded={setIsLoaded}>{isLoaded && <Pages />}</AuthProvider>
    </QueryClientProvider>
  )
}

export default App
