import React from "react"
import { FcCheckmark } from "react-icons/fc"
import { Button } from "react-bootstrap"

type Plan = {
  size: string
  title: string
  text: string
  registerFee: string
  monthlyFee: string
  portalAble: Array<string>
  facebookAble: Array<string>
  link: string
}

export const PlanList = (props: Plan) => {
  const size = props.size
  const title = props.title
  const text = props.text
  const registerFee = props.registerFee
  const monthlyFee = props.monthlyFee
  const portalAble = props.portalAble
  const facebookAble = props.facebookAble
  const link = props.link
  return (
    <div className={`plan-list d-grid col-lg-${size} position-relative border border-dark rounded p-4`}>
      <div>
        <h4 className="plan-title fw-bold mb-4">{title}</h4>
        <p className="mb-3">{text}</p>
        <p className="mb-0">登録料: ¥{registerFee}</p>
        <p className="border-bottom border-dark mb-4 pb-4 mb-0">月額: ¥{monthlyFee}</p>
        <h6 className="fw-bold mb-3">ポータルサイト利用</h6>
        <ul className="plan-able list-unstyled mb-5 ps-2">
          {portalAble.map((value) => (
            <li key={value} className="mb-2">
              <FcCheckmark />
              &nbsp;
              {value}
            </li>
          ))}
        </ul>
        <h6 className="fw-bold mb-3">FaceBook利用</h6>
        <ul className="plan-able list-unstyled mb-5 ps-2 pb-5">
          {facebookAble.map((value) => (
            <li key={value} className="mb-2">
              <FcCheckmark />
              &nbsp;
              {value}
            </li>
          ))}
        </ul>
        <div className="position-absolute bottom-0 start-50 translate-middle-x d-grid col-6 text-center mx-auto mb-4">
          <Button className="rounded-pill fw-bold p-2" variant="dark" href={link}>
            申し込む
          </Button>
        </div>
      </div>
    </div>
  )
}
