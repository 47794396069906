import React from "react"
import { Button, Navbar } from "react-bootstrap"

export const CorporateSiteLink = () => {
  return (
    <>
      <Navbar.Text>
        <Button
          className="me-lg-3"
          variant="light"
          target="blank"
          rel="noopener noreferrer"
          href="https://surfnationjapan.jp"
        >
          HPはこちら
        </Button>
      </Navbar.Text>
    </>
  )
}
