import { useInfiniteQuery } from "@tanstack/react-query"
import * as apigw from "@/lib/apigw"
import { IMovie } from "@/features/categoryMovie"

export const useNotices = (nextId: string, opts?: any) => {
  return useInfiniteQuery(
    ["notice", nextId],
    async () => {
      const url = "/user/get-notices"
      const { data } = await apigw.request<IMovie[]>(url, { nextId }, opts)
      return data
    },
    {
      getNextPageParam: (lastPage) => lastPage[lastPage.length - 1]?.nextId,
    }
  )
}
