import React, { useContext } from "react"
import Spinner from "react-bootstrap/Spinner"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Button from "react-bootstrap/Button"
import { ShowThumbnailMovie, IMovie } from ".."
import { AuthContext } from "@/lib/auth"
import { useCategory, useMovies } from "@/hooks"

type Props = { currentMovieId?: string | undefined; categoryId?: string; playerPage?: boolean }

export const ShowCategoryMovies = ({ currentMovieId, categoryId, playerPage }: Props) => {
  const auth = useContext(AuthContext)

  const categoryQuery = useCategory(categoryId)
  const { data: category } = categoryQuery

  const moviesQuery = useMovies(categoryId, 4, auth.currentUser?.cognitoId)
  const { data: movies, error, fetchNextPage, hasNextPage, isFetchingNextPage, isLoading } = moviesQuery

  if (error) {
    console.error(error)
  }

  let lastMovie
  if (movies && movies.pages.length > 0) {
    const lastPage = movies.pages[movies.pages.length - 1] //movies.pages配列の最後の要素
    lastMovie = lastPage[lastPage.length - 1]
  }

  return (
    <>
      <div className="contents-box ">
        <h5 className="sub-title">{category?.name}の動画</h5>
        {isLoading && (
          <div className="w-100 text-center">
            <Spinner animation="border" variant="info" />
          </div>
        )}
        {!isLoading && movies && !playerPage && (
          <>
            <Row>
              {movies.pages.map((moviePage) =>
                moviePage.map((movie: IMovie) => (
                  <Col md={4} key={`movie-${movie.id}`}>
                    <ShowThumbnailMovie currentMovieId={currentMovieId} movie={movie} />
                  </Col>
                ))
              )}
            </Row>
            {lastMovie && lastMovie.nextId && category && (
              <Button
                onClick={() => fetchNextPage()}
                disabled={!hasNextPage || isFetchingNextPage}
                className="btn-block btn-primary"
              >
                {isFetchingNextPage ? "Loading more..." : "もっと見る"}
              </Button>
            )}
          </>
        )}
        {!isLoading && movies && playerPage && currentMovieId && (
          <>
            {movies?.pages.map((page) =>
              page.map((movie: IMovie) => {
                return (
                  <Col key={`movie-${movie.id}`} className="mb-4">
                    <ShowThumbnailMovie currentMovieId={currentMovieId} movie={movie} playerPage={playerPage} />
                  </Col>
                )
              })
            )}

            {lastMovie?.nextId && category && (
              <Button onClick={() => fetchNextPage()} className="btn-block btn-primary">
                {isFetchingNextPage ? "Loading more..." : "もっと見る"}
              </Button>
            )}
          </>
        )}

        {!isLoading && !movies && <div className="alert alert-danger bg-light m-4">動画が登録されていません</div>}
      </div>
    </>
  )
}
