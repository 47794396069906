import React, { useState, useCallback } from "react"
import { useLocation } from "react-router-dom"
import { Button, Form, Row, Col } from "react-bootstrap"
import { Link } from "react-router-dom"
import queryString from "query-string"

export const ShowSearchMovie = () => {
  const search = useLocation().search
  const query = queryString.parse(search)
  let defaultSearchText = ""
  if (query?.searchText && !Array.isArray(query?.searchText)) {
    defaultSearchText = query.searchText || ""
  }

  const [searchText, setSearchText] = useState(defaultSearchText)

  const handleSearchChange = useCallback((e: any) => setSearchText(e.target.value), [])

  return (
    <>
      <Row>
        <Col md={10} xs={9} className="pt-3 pb-2">
          <Form>
            <Form.Control
              type="text"
              name="name"
              value={searchText}
              placeholder="動画を検索"
              onChange={handleSearchChange}
            />
          </Form>
        </Col>
        <Col md={2} xs={3} className="pt-3 pb-2">
          <Link to={`/movie/search-list?searchText=${searchText}`}>
            <Button>
              <span>検索</span>
            </Button>
          </Link>
        </Col>
      </Row>
    </>
  )
}
