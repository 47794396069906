import * as apigw from "@/lib/apigw"

type GetSearchMovieListParams = {
  nextId?: string
  searchText?: string
  opts?: any
}

export const getSearchMovieListDb = async (params: GetSearchMovieListParams): Promise<any> => {
  const res = await apigw.request("/user/get-search-movie-list", { ...params }, params.opts)
  return res
}
