import { useInfiniteQuery } from "@tanstack/react-query"
import * as apigw from "@/lib/apigw"
import { IMovie } from "@/features/categoryMovie"

export const useMovies = (categoryId?: string, limit = 8, cognitoId?: string) => {
  return useInfiniteQuery(
    ["movies", categoryId, limit, cognitoId],
    async ({ pageParam = undefined }) => {
      const url = "/user/get-movies"
      const { data } = await apigw.request<IMovie[]>(url, { categoryId, limit, nextId: pageParam, cognitoId })
      return data
    },
    {
      getNextPageParam: (lastPage) => lastPage[lastPage.length - 1]?.nextId,
    }
  )
}
