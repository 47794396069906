import React from "react"
import { Routes, BrowserRouter as Router, Route } from "react-router-dom"
import MyRoute from "./lib/custom-route"
import { Home } from "./pages/home"
import { ShowCategory } from "./pages/category/show"
import { ShowMovie } from "./pages/movie/show"
import { RecentListMovie } from "./pages/movie/recent-list"
import SignIn from "./pages/auth/signin"
import SignOut from "./pages/auth/signout"
import PasswordResetForm from "./pages/auth/PasswordResetForm"
import ForgotPasswordForm from "./pages/auth/ForgotPasswordForm"
import { PageNotFound } from "./pages/page-not-found"
import { SearchListMovie } from "./pages/movie/search-list"
import { About } from "./pages/about"

function App() {
  return (
    <Router>
      <Routes>
        <Route path="auth">
          <Route path="signin" element={<MyRoute Component={<SignIn />} wrap contents id="auth-signin" />} />
          <Route path="signout" element={<MyRoute Component={<SignOut />} wrap auth contents id="auth-signout" />} />
          <Route
            path="forgot-password"
            element={<MyRoute Component={<ForgotPasswordForm />} wrap auth contents id="forgot-password" />}
          />
          <Route
            path="password-reset"
            element={<MyRoute Component={<PasswordResetForm />} wrap auth contents id="auth-passowrd-reset" />}
          />
        </Route>
        <Route path="category">
          <Route
            path=":id"
            element={<MyRoute Component={<ShowCategory />} wrap auth contents id="category-detail" />}
          />
        </Route>
        <Route path="movie">
          <Route path=":id" element={<MyRoute Component={<ShowMovie />} wrap auth contents id="movie-detail" />} />
          <Route
            path="recent-list"
            element={<MyRoute Component={<RecentListMovie />} wrap auth contents id="movie-recent-list" />}
          />
          <Route
            path="search-list"
            element={<MyRoute Component={<SearchListMovie />} wrap auth contents id="movie-search-list" />}
          />
        </Route>
        <Route path="/about" element={<MyRoute Component={<About />} wrap auth contents id="about" />} />
        <Route path="/" element={<MyRoute Component={<Home />} wrap auth contents id="home" />} />
        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </Router>
  )
}

export default App
