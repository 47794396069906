import React from "react"
import Spinner from "react-bootstrap/Spinner"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Button from "react-bootstrap/Button"
import { IMovie, ShowThumbnailMovie } from ".."
import { useRecentMovieList } from "@/hooks/useRecentMovieList"

export const RecentMovieList = () => {
  const recentMovieListQuery = useRecentMovieList()
  const { data: recentMovies, error, fetchNextPage, isFetchingNextPage, isLoading } = recentMovieListQuery

  if (error) {
    console.error(error)
  }

  let lastMovie
  if (recentMovies && recentMovies.pages.length > 0) {
    const lastPage = recentMovies.pages[recentMovies.pages.length - 1]
    lastMovie = lastPage[lastPage.length - 1]
  }

  return (
    <>
      <div className="contents-box">
        <h5 className="sub-title">新着動画一覧</h5>
        {isLoading && (
          <div className="w-100 text-center">
            <Spinner animation="border" variant="info" />
          </div>
        )}
        {!isLoading && recentMovies && (
          <>
            <Row>
              {recentMovies?.pages.map((recentMoviePage) =>
                recentMoviePage.map((recentMovie: IMovie) => (
                  <Col md={6} xl={3} key={`recentMovie-${recentMovie.id}`} className="p-3">
                    <div className="border p-3 h-100">
                      <ShowThumbnailMovie currentMovieId="" movie={recentMovie} />
                    </div>
                  </Col>
                ))
              )}
            </Row>
            {lastMovie?.nextId && (
              <Button onClick={() => fetchNextPage()} className="btn-block btn-primary">
                {isFetchingNextPage ? "Loading more..." : "もっと見る"}
              </Button>
            )}
          </>
        )}
        {!isLoading && !recentMovies && <div className="alert alert-danger bg-light m-4">動画が登録されていません</div>}
      </div>
    </>
  )
}
