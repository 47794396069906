import React, { useContext, useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { Card, Button, Form } from "react-bootstrap"
import { AuthContext } from "@/lib/auth"
import { CustomModal } from "@/components"
import { Link } from "react-router-dom"

export default () => {
  const [errorMessage, setErrorMessage] = useState<string>("")
  const [modalShow, setModalShow] = useState<boolean>(false)
  const [user, setUser] = useState<any>(null)
  const authContext = useContext(AuthContext)
  const history = useNavigate()

  useEffect(() => {
    errorMessage !== "" && setModalShow(true)
  }, [errorMessage])

  useEffect(() => {
    !modalShow && setErrorMessage("")
  }, [modalShow])

  useEffect(() => {
    if (authContext.currentUser) {
      window.location.href = "/"
    }
  }, [authContext.currentUser, history, user])

  return (
    <section>
      <CustomModal show={modalShow} message={errorMessage} onHide={() => setModalShow(false)} />
      <SignIn {...{ setUser, setErrorMessage }} />
    </section>
  )
}

const SignIn = (props: any) => {
  const auth = useContext(AuthContext)

  const [username, setUsername] = useState<string>("")
  const [password, setPassword] = useState<string>("")

  const [attempting, setAttempting] = useState<boolean>(false)

  const handleUsernameChange = (e: { target: { value: React.SetStateAction<string> } }) => setUsername(e.target.value)
  const handlePasswordChange = (e: { target: { value: React.SetStateAction<string> } }) => setPassword(e.target.value)

  const handleSignIn = async () => {
    setAttempting(true)
    try {
      const data = await auth.signIn(username, password)
      setAttempting(false)
      props.setUser(data)
    } catch (err: any) {
      setAttempting(false)
      props.setErrorMessage(err.message)
    }
  }

  return (
    <Card>
      <Card.Header>
        <img src="/img/logo.png" />
      </Card.Header>
      <Card.Body className="m-3">
        <Form>
          <Form.Group>
            <Form.Label className="fw-bold mb-2">メールアドレス</Form.Label>
            <Form.Control
              type="text"
              name="username"
              placeholder="ID"
              autoComplete="username"
              onChange={handleUsernameChange}
              disabled={attempting}
              className="mb-1"
            />
          </Form.Group>
          <Form.Group className="mt-4">
            <Form.Label className="fw-bold mb-2">パスワード</Form.Label>
            <Form.Control
              type="password"
              name="password"
              placeholder="パスワード"
              autoComplete="password"
              onChange={handlePasswordChange}
              disabled={attempting}
            />
          </Form.Group>
          <hr className="my-4" />
          <Form.Group>
            <Button className="btn-primary btn-block w-100 mb-4" onClick={handleSignIn}>
              <span>送信</span>
            </Button>
            <span>{<Link to="/auth/forgot-password">パスワードをお忘れの方はこちら</Link>}</span>
          </Form.Group>
        </Form>
      </Card.Body>
    </Card>
  )
}
