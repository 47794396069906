import { PlanList, RegisterStep } from "@/components"
import React from "react"
import { Stack, Col, Container, Table, Image } from "react-bootstrap"
import { AiOutlineArrowDown } from "react-icons/ai"
import { FcCheckmark, FcCancel } from "react-icons/fc"

import mv_about from "@/img/mv_about.jpeg"
import Logo from "@/img/logo.png"

export const About = () => {
  const iconSize = "48px"
  return (
    <>
      <Container className="contents-box p-md-5">
        <div className="mv-about-wrap position-relative section-margin">
          <h2 className="mv-about-title position-absolute h2 fw-bold text-center text-light text-break">
            <img src={Logo} alt="" className="w-50 d-none d-md-inline-block mb-3" />
            <span className="d-none d-md-block">サーフィンの世界が1クリックで広がる</span>
            Surfnationの
            <br className="d-block d-md-none" />
            オンラインレッスン
          </h2>
          <Image className="mv-about" src={mv_about} alt="" width="100%" />
        </div>
        <section className="section-margin">
          <h4 className="h1 text-center mb-4">初めてのお客様へ</h4>
          {/* <ShowCarousel /> */}
          <Col className="text-center lh-lg">
            <p>
              当サイトをご覧いただき、誠にありがとうございます。
              <br />
              当サイトは、サーフィンレッスン、体幹トレーニングのオンラインレッスンを行うためのプラットフォームとなるシステムです。
              <br />
              ご登録からレッスン受講までの手順をご案内いたします。
            </p>
          </Col>
        </section>
        <section className="section-margin">
          <div className="section-margin col-md-8 mx-auto">
            <Col className="text-center">
              <h5 className="h2 mb-4">Surfnationについて</h5>
            </Col>
            <div className="text-center mx-auto">
              <h4 className="h5 mb-4">こんなお悩みありませんか？</h4>
              <ul className="online-lesson-list rounded mb-4 p-5">
                <li className="mb-2">週末サーフィンに行くと身体が重い...</li>
                <li className="mb-2">基本動作が身に付いていない...</li>
                <li className="mb-2">もっともっと上手くなりたいけど週末しか海に行けない...</li>
              </ul>
              <p className="lh-lg mb-4">
                忙しい日常に縛られず、どこでもアクセス可能。基本から上達テクニックまで、
                専門インストラクターが丁寧に解説する、サーフィンのオンライン学習。
                ポジショニングやテイクオフ、ターンのコツもわかりやすく指導します。
              </p>
            </div>
          </div>
          <div className="col-md-8 text-center mx-auto">
            <h5 className="h2 mb-3">見て学ぶ</h5>
            <figure>
              <figcaption className="lh-lg mb-3">
                いつでもどこでも学べる！
                <br className="d-block d-md-none" />
                波に乗る喜びを動画学習で満喫しよう。
                <br />
                自己成長の醍醐味がここにあります。
              </figcaption>
              <img src="../img/top-image.png" alt="" className="w-100 mb-4" />
            </figure>
          </div>
        </section>
        <section className="section-margin">
          <Col className="text-center">
            <h5 className="h2 mb-3">Surfnationの登録方法</h5>
            <p>下記の手順でSurfnationにご登録ください。</p>
          </Col>
          <RegisterStep
            step={"1"}
            image={"../img/member-register.png"}
            text={"下記URLにアクセスし、必要事項を入力して会員登録をしてください。"}
            link={"https://www.kaihipay.jp/forms?form_code=5909146335907280"}
            buttonText={"登録ページ"}
          />
          <div className="text-center">
            <AiOutlineArrowDown className="mx-auto mb-4" size={iconSize} />
          </div>
          <RegisterStep
            step={"2"}
            image={"../img/signin-image.png"}
            text={"会員登録が完了したら、下記ページより、ログインしてください。"}
            link={"/auth/signin"}
            buttonText={"ログイン"}
          />
          <div className="text-center">
            <AiOutlineArrowDown className="mx-auto mb-4" size={iconSize} />
          </div>
          <RegisterStep
            step={"3"}
            image={"../img/top-image.png"}
            text={"登録したライセンスに応じて、レッスンの受講が可能になります。"}
            link={"/"}
            buttonText={"TOPページへ"}
          />
        </section>
        <section className="section-margin">
          <Col className="text-center">
            <h5 className="h2 mb-3">料金プラン</h5>
            <p className="mb-4">オンラインサロンとオンラインレッスンの2つのプランがあります。</p>
          </Col>
          <div className="col-12 section-margin">
            <Stack
              direction="horizontal"
              gap={5}
              className="flex-wrap justify-content-center align-items-stretch col-md-10 mx-auto"
            >
              <PlanList
                size={"5"}
                title={"オンラインサロン"}
                text={
                  "ポータルサイト上の動画コンテンツを通じて、サーフィンの基本的なテクニックや情報を学び、メンバーとの交流や情報共有を楽しむことができます。"
                }
                registerFee={"1,100"}
                monthlyFee={"1,650"}
                portalAble={["座学", "イメージ", "シャドー", "コンビネーション", "フロー"]}
                facebookAble={["SNJ now", "SNJオンラインサロン 動画投稿月に二回まで"]}
                link={"https://www.kaihipay.jp/forms?form_code=5909146335907280"}
              />
              <PlanList
                size={"5"}
                title={"オンラインレッスン"}
                text={
                  "オンラインサロンプランの特典に加え、週に複数回のオンラインレッスンに参加することで、サーフィンのテクニックや戦略を向上させることができます。"
                }
                registerFee={"1,100"}
                monthlyFee={"8,800"}
                portalAble={["座学", "イメージ", "シャドー", "SK8", "ドリル", "コンビネーション", "フロー"]}
                facebookAble={["SNJ now", "SNJオンラインサロン 動画投稿月に二回まで", "オンライントレーニング参加"]}
                link={"https://www.kaihipay.jp/forms?form_code=5909146335907280"}
              />
            </Stack>
          </div>
        </section>
        <section className="section-margin">
          <Col className="text-center mb-4">
            <h5 className="h2 mb-3">プランの比較</h5>
          </Col>
          <div className="col-lg-8 mx-auto">
            <Table striped bordered border={2} hover>
              <thead>
                <tr>
                  <th></th>
                  <th className="text-center">オンラインサロン</th>
                  <th className="text-center">オンラインレッスン</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th>座学</th>
                  <td className="text-center">
                    <FcCheckmark />
                  </td>
                  <td className="text-center">
                    <FcCheckmark />
                  </td>
                </tr>
                <tr>
                  <th>イメージ</th>
                  <td className="text-center">
                    <FcCheckmark />
                  </td>
                  <td className="text-center">
                    <FcCheckmark />
                  </td>
                </tr>
                <tr>
                  <th>シャドー</th>
                  <td className="text-center">
                    <FcCheckmark />
                  </td>
                  <td className="text-center">
                    <FcCheckmark />
                  </td>
                </tr>
                <tr>
                  <th>SK8</th>
                  <td className="text-center">
                    <FcCancel />
                  </td>
                  <td className="text-center">2024年公開</td>
                </tr>
                <tr>
                  <th>ドリル</th>
                  <td className="text-center">
                    <FcCancel />
                  </td>
                  <td className="text-center">
                    <FcCheckmark />
                  </td>
                </tr>
                <tr>
                  <th>コンビネーション</th>
                  <td className="text-center">
                    <FcCancel />
                  </td>
                  <td className="text-center">2024年公開</td>
                </tr>
                <tr>
                  <th>フロー</th>
                  <td className="text-center">
                    <FcCancel />
                  </td>
                  <td className="text-center">2024年公開</td>
                </tr>
                <tr>
                  <th>SNJ now</th>
                  <td className="text-center">
                    <FcCheckmark />
                  </td>
                  <td className="text-center">
                    <FcCheckmark />
                  </td>
                </tr>
                <tr>
                  <th>SNJオンラインサロン</th>
                  <td className="text-center align-middle">
                    <FcCheckmark />
                  </td>
                  <td className="text-center align-middle">
                    <FcCheckmark />
                  </td>
                </tr>
                <tr>
                  <th>オンライントレーニング参加</th>
                  <td className="text-center align-middle">
                    <FcCancel />
                  </td>
                  <td className="text-center align-middle">
                    <FcCheckmark />
                  </td>
                </tr>
              </tbody>
            </Table>
          </div>
        </section>
        <div className="m-4 text-center about-footer">
          <p className="font-weight-bold">
            反復練習が難しいサーフィンですが、オンラインレッスンであれば、何度でも反復練習ができます。
          </p>
          <p className="font-weight-bold">
            パドリングやテイクオフ、そしてボトムターンやリップも動きを丘でマスターすることによってサーフィンの上達を早めることができます。
          </p>
          <p className="font-weight-bold">ぜひ一緒にサーフィンを楽しみましょう！</p>
        </div>
      </Container>
    </>
  )
}
