import React, { useState, useEffect } from "react"
import { useLocation } from "react-router-dom"
import queryString from "query-string"
import Spinner from "react-bootstrap/Spinner"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Button from "react-bootstrap/Button"
import { IMovie, ShowThumbnailMovie, getSearchMovieListDb } from ".."

export const SearchMovieList = () => {
  const [isLoaded, setIsLoaded] = useState<boolean>(true)
  const search = useLocation().search
  const query = queryString.parse(search)

  const [searchMovies, setSearchMovies] = useState<IMovie[]>([])

  useEffect(() => {
    if (query.searchText) {
      getSearchMovies({ initial: true })
    }
  }, [query.searchText])

  type Props = { nextId?: string; initial?: boolean }

  async function getSearchMovies({ nextId, initial }: Props) {
    try {
      setIsLoaded(true)
      //query?.searchTextがあって、かつ searchTextが配列でない場合
      if (query?.searchText && !Array.isArray(query?.searchText)) {
        const searchText: string = query?.searchText
        const json: any = await getSearchMovieListDb({ nextId: nextId, searchText: searchText })
        if (json?.data) {
          if (searchMovies.length && !initial) {
            setSearchMovies([...searchMovies, ...json.data])
          } else {
            setSearchMovies(json.data)
          }
        }
      }
    } catch (err) {
      if (err instanceof Error) {
        console.log(err.message)
      }
    }
    setIsLoaded(false)
  }

  return (
    <>
      <div className="contents-box">
        <h5 className="sub-title">検索結果</h5>
        {isLoaded && (
          <div className="w-100 text-center">
            <Spinner animation="border" variant="info" />
          </div>
        )}
        {!isLoaded && searchMovies.length > 0 && query.searchText && (
          <>
            <Row>
              {searchMovies?.map((searchMovie: IMovie, index: number) => {
                return (
                  <Col md={6} xl={3} key={`movie-${index}`} className="p-3">
                    <div className="border p-3 h-100">
                      <ShowThumbnailMovie currentMovieId="" movie={searchMovie} />
                    </div>
                  </Col>
                )
              })}
            </Row>
            {searchMovies.slice(-1)[0].nextId && (
              <Button
                onClick={() => getSearchMovies({ nextId: searchMovies?.slice(-1)[0].nextId })}
                className="btn-block btn-primary"
              >
                もっと見る
              </Button>
            )}
          </>
        )}
        {!isLoaded && !searchMovies.length && query.searchText && (
          <div className="alert alert-danger bg-light m-4">動画が見つかりません</div>
        )}
      </div>
    </>
  )
}
