import React, { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import Spinner from "react-bootstrap/Spinner"
import { getBreadcrumbDb } from "@/features/categoryMovie"
import { BsPlayFill } from "react-icons/bs/"
import Col from "react-bootstrap/Col"
import { ShowBreadCrumbTree } from "./"

type Props = { categoryId?: string }
type Breadcrumb = { name?: string; id?: string; parentId?: string; link: string }

const initialBreadcrumb: Breadcrumb = { name: "TOP", link: "/" }

export const Breadcrumb = ({ categoryId }: Props) => {
  const [breadcrumbs, setBreadcrumbs] = useState<Breadcrumb[]>([])
  const [breadcrumbsTree, setBreadcrumbsTree] = useState<Breadcrumb[]>([])
  const [isLoaded, setIsLoaded] = useState<boolean>(true)

  useEffect(() => {
    getBreadcrumb()
  }, [categoryId])

  const getBreadcrumb = async () => {
    if (categoryId) {
      try {
        setIsLoaded(true)
        const json: any = await getBreadcrumbDb({ categoryId: categoryId })
        if (json?.data) {
          setBreadcrumbs([initialBreadcrumb, ...json.data.parentBreadcrumbs])
          setBreadcrumbsTree(json.data.childBreadcrumbs)
        }
      } catch (err) {
        if (err instanceof Error) {
          console.log(err.message)
        }
      }
    }
    setIsLoaded(false)
  }

  const currentCategory = breadcrumbs[breadcrumbs.length - 1]

  return (
    <>
      {isLoaded && (
        <div className="w-100 text-center bg-light mb-4">
          <Spinner animation="border" variant="info" />
        </div>
      )}
      {!isLoaded && breadcrumbs.length > 0 && (
        <>
          <Col className="contents-box p-2 d-none d-md-block">
            <>
              {breadcrumbs
                .filter((_, index) => index < breadcrumbs.length - 1)
                .map((breadcrumb: Breadcrumb, index: number) => {
                  if (breadcrumbs.length === index + 1) {
                    return (
                      <div key={`breadcrumb-${index}`} className="m-2 d-inline-block">
                        {breadcrumb.name}
                      </div>
                    )
                  } else {
                    return (
                      <div key={`breadcrumb-${index}`} className="m-2 d-inline-block">
                        <Link to={breadcrumb.link} className="">
                          {breadcrumb.name}
                        </Link>
                        <BsPlayFill className="ms-4" />
                      </div>
                    )
                  }
                })}
            </>
            <ShowBreadCrumbTree allCategories={breadcrumbsTree} currentCategory={currentCategory} />
          </Col>
        </>
      )}
    </>
  )
}
