import Auth from "@aws-amplify/auth"
import axios from "axios"

export const request = async <T>(path: string, body?: any, opts?: any) => {
  let accessToken = ""
  try {
    const user = await Auth.currentAuthenticatedUser()
    accessToken = user?.signInUserSession.accessToken.jwtToken
  } catch (err) {
    // console.log("No user")
  }
  const endPoint = process.env.REACT_APP_API_ENDPOINT
  return axios.post<T>(
    `${endPoint}${path}`,
    { ...body },
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: accessToken,
      },
      ...opts,
    }
  )
}

export const isCancel = (err: any) => axios.isCancel(err)

export const getCancelTokenSource = () => axios.CancelToken.source()
