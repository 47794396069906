import React from "react"
import { createRoot } from "react-dom/client"

import "bootstrap/dist/css/bootstrap.min.css"
import "./css/css"

import App from "./app"
import { Amplify } from "@aws-amplify/core"
import config from "./config/config"
Amplify.configure({
  ...config,
  Auth: {
    ...config,
    cookieStorage: {
      domain: window.location.hostname,
      path: "/",
      expires: 14,
      samesite: "None",
      secure: true,
    },
  },
})

const container = document.getElementById("root")
const root = createRoot(container!)

root.render(<App />)
