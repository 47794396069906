import React, { useContext } from "react"
import { Link } from "react-router-dom"
import Spinner from "react-bootstrap/Spinner"
import { ICategory, IMovie, ShowThumbnailMovie } from ".."
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import { AuthContext } from "@/lib/auth"
import { useChildMovies } from "@/hooks/"

type Props = { category: ICategory }

export const ShowChildCategoryMovies = ({ category }: Props) => {
  const auth = useContext(AuthContext)

  const childCategoryMoiviesQuery = useChildMovies
  const { data: childCategoryMovies, isLoading } = childCategoryMoiviesQuery(
    category.id,
    4,
    auth.currentUser?.cognitoId
  )

  return (
    <>
      <div className="contents-box">
        <h5 className="sub-title">
          {category?.name}の動画
          <Link to={`/category/${category.id}/`} className="btn btn-primary ms-3 ">
            もっと見る
          </Link>
        </h5>
        {isLoading && (
          <div className="w-100 text-center">
            <Spinner animation="border" variant="info" />
          </div>
        )}
        {!isLoading && childCategoryMovies && (
          <>
            <Row>
              {childCategoryMovies?.map((childCategoryMovie: IMovie, index: number) => {
                return (
                  <Col md={6} xl={3} key={`movie-${index}`} className="p-3">
                    <div className="border p-3 h-100">
                      <ShowThumbnailMovie currentMovieId="" movie={childCategoryMovie} />
                    </div>
                  </Col>
                )
              })}
            </Row>
          </>
        )}
        {!isLoading && !childCategoryMovies && (
          <div className="alert alert-danger bg-light m-4">動画が登録されていません</div>
        )}
      </div>
    </>
  )
}
