import { useQuery } from "@tanstack/react-query"
import * as apigw from "@/lib/apigw"
import { IMovie } from "@/features/categoryMovie"

export const useChildMovies = (categoryId?: string, limit?: number, cognitoId?: string, opts?: any) => {
  return useQuery({
    queryKey: ["movie", [categoryId, limit, cognitoId]],
    queryFn: async () => {
      const url = "/user/get-child-movies"
      const { data } = await apigw.request<IMovie[]>(url, { categoryId, limit, cognitoId }, opts)
      return data
    },
  })
}
