import { useQuery } from "@tanstack/react-query"
import * as apigw from "@/lib/apigw"
import { IMovie } from "@/features/categoryMovie"

export const useMovie = (id: string, opts?: any) => {
  return useQuery({
    queryKey: ["movie", id],
    queryFn: async () => {
      const url = "/user/get-movie"
      const { data } = await apigw.request<IMovie>(url, { id }, opts)
      return data
    },
  })
}
