import { useInfiniteQuery } from "@tanstack/react-query"
import * as apigw from "@/lib/apigw"
import { ICategory } from "@/features/categoryMovie"

export const useCategories = (parentId?: string, limit?: number, nextId?: string, opts?: any) => {
  return useInfiniteQuery(
    ["category", parentId, limit, nextId],
    async ({ pageParam = nextId }) => {
      const url = "/user/get-categories"
      const { data } = await apigw.request<ICategory[]>(url, { parentId, limit, nextId: pageParam }, opts)
      return data
    },
    {
      getNextPageParam: (lastPage) => lastPage[lastPage.length - 1]?.nextId,
    }
  )
}
