import React, { useEffect, useState } from "react"
import { IMovie } from ".."
import Image from "react-bootstrap/Image"

type Props = { movie: IMovie }

export const SelectThumbnail = ({ movie }: Props) => {
  const [thumbnailSrc, setThumbnailSrc] = useState<string>("")

  useEffect(() => {
    if (movie?.thumbnailNumber === 0) {
      setThumbnailSrc(`https://${process.env.REACT_APP_DOMAIN}/${movie?.thumbnailFile}`)
    } else if (movie?.thumbnailNumber === 1) {
      setThumbnailSrc(
        `https://${process.env.REACT_APP_DOMAIN}/${movie?.hlsFile?.replace("movies", "images")}_thumbnail.0000000.jpg`
      )
    } else if (movie?.thumbnailNumber === 2) {
      setThumbnailSrc(
        `https://${process.env.REACT_APP_DOMAIN}/${movie?.hlsFile?.replace("movies", "images")}_thumbnail.0000001.jpg`
      )
    } else if (movie?.thumbnailNumber === 3) {
      setThumbnailSrc(
        `https://${process.env.REACT_APP_DOMAIN}/${movie?.hlsFile?.replace("movies", "images")}_thumbnail.0000002.jpg`
      )
    }
  }, [movie])

  return (
    <>
      <Image className="thumbnail" src={thumbnailSrc} fluid />
    </>
  )
}
