import React, { useState } from "react"
import Spinner from "react-bootstrap/Spinner"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Button from "react-bootstrap/Button"
import { CustomModal } from "@/components"

import { INotice } from ".."
import { useNotices } from "@/hooks/useNotices"

export const ShowNotices = () => {
  const [noticeText, setNoticeText] = useState("")
  const [modalShow, setModalShow] = useState<boolean>(false)

  const noticesQuery = useNotices("")
  const { data: notices, fetchNextPage, isFetchingNextPage, isLoading } = noticesQuery

  let lastNotice
  if (notices && notices.pages.length > 0) {
    lastNotice = notices.pages[notices.pages.length - 1]
    lastNotice = lastNotice[lastNotice.length - 1]
  }

  const dateFormat = (date: string) => {
    const targetDate = new Date(date)
    const year = targetDate.getFullYear()
    const month = targetDate.getMonth() + 1
    const day = targetDate.getDate()
    return `${year}年${month}月${day}日`
  }

  return (
    <>
      {isLoading && (
        <div className="w-100 text-center">
          <Spinner animation="border" variant="info" />
        </div>
      )}
      {!isLoading && notices && (
        <div className="h-100">
          <h5 className="sub-title">お知らせ</h5>
          {notices?.pages.map((page) =>
            page.map((notice: INotice) => {
              return (
                <Row key={`notice-${notice.id}`}>
                  <Col sm={12} md="auto" className="mb-1">
                    {dateFormat(notice.date || "")}
                  </Col>
                  <Col sm={12} md="auto">
                    <a
                      href="#"
                      onClick={(event) => {
                        event.preventDefault()
                        setModalShow(true)
                        if (notice.text) {
                          setNoticeText(notice.text)
                        }
                      }}
                    >
                      {notice.title}
                    </a>

                    {
                      <CustomModal
                        title={`${dateFormat(notice.date || "")} お知らせ`}
                        show={modalShow}
                        message={noticeText}
                        size="xl"
                        onHide={() => setModalShow(false)}
                      />
                    }
                  </Col>
                </Row>
              )
            })
          )}
          {lastNotice?.nextId && (
            <>
              <div className="text-center w-100 position-absolute bottom-0 mb-3">
                <Button onClick={() => fetchNextPage()} className="btn btn-primar w-75">
                  {isFetchingNextPage ? "Loading more..." : "もっと見る"}
                </Button>
              </div>
            </>
          )}
        </div>
      )}
    </>
  )
}
