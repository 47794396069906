import { useQuery } from "@tanstack/react-query"
import * as apigw from "@/lib/apigw"
import { ICategory } from "@/features/categoryMovie"

export const useAllCategories = (parentId?: string, opts?: any) => {
  return useQuery({
    queryKey: ["allcategories", parentId],
    queryFn: async () => {
      const url = "/user/get-all-categories"
      const { data } = await apigw.request<ICategory[]>(url, { parentId }, opts)
      return data
    },
  })
}
