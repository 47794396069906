import React from "react"
import Spinner from "react-bootstrap/Spinner"
import Button from "react-bootstrap/Button"
import { ICategory, ShowChildCategoryMovies } from ".."

import { useCategories } from "@/hooks/useCategories"
type Props = { parentId?: string; nextId?: string }
// type GetCategoriesProps = { init?: boolean; nextId?: string }

export const ShowChildCategories = ({ parentId }: Props) => {
  const childCategoriesQuery = useCategories(parentId, 5)
  const { data: childCategories, fetchNextPage, hasNextPage, isFetchingNextPage, isLoading } = childCategoriesQuery

  const lastPage = childCategories?.pages[childCategories?.pages.length - 1]
  const lastCategory = lastPage?.[lastPage.length - 1]

  return (
    <>
      {isLoading && (
        <div className="w-100 text-center">
          <Spinner animation="border" variant="info" />
        </div>
      )}
      {!isLoading && childCategories && (
        <>
          {childCategories?.pages.map((page) =>
            page.map((childCategory: ICategory) => {
              return (
                <div key={`category-${childCategory.id}`}>
                  <ShowChildCategoryMovies category={childCategory} />
                </div>
              )
            })
          )}
          {hasNextPage && lastCategory?.nextId && (
            <Button onClick={() => fetchNextPage()} className="btn-block btn-primary">
              {isFetchingNextPage ? "Loading more..." : "もっと見る"}
            </Button>
          )}
        </>
      )}
    </>
  )
}
