import React, { useState, useRef, useContext } from "react"
import { AuthContext } from "@/lib/auth"
import { SelectThumbnail } from ".."
import ReactPlayer from "react-player"
import { useNavigate } from "react-router-dom"
import { ShowCategoryMovies, setMoviePlayDataDb, updateMoviePlayCountDb, ShowChildCategories } from ".."
import Col from "react-bootstrap/Col"
import { checkMovieAccess } from "@/features/categoryMovie"
import { BsFillLockFill } from "react-icons/bs/"

import { useMovie } from "@/hooks"

type Props = { id: string }
type PlayProgress = { played: number; playedSeconds: number; loaded: number; loadedSeconds: number }

export const ShowPlayMovie = ({ id }: Props) => {
  const auth = useContext(AuthContext)
  const [playProgressRate, setPlayProgressRate] = useState<number>(0)

  const navigate = useNavigate()

  const movieQuery = useMovie(id)
  const { data: movie } = movieQuery

  if (movie) {
    console.log(movie)
  }

  const player = useRef<ReactPlayer>(null)

  const onStartedMovie = async () => {
    console.log("start!")
    //ここで再生データを登録
    if (movie) {
      try {
        const json: any = await setMoviePlayDataDb(movie.id)
        console.log(json)
        //単純な視聴回数を保存
        const playCountJson: any = await updateMoviePlayCountDb(movie.id)
        console.log(playCountJson)
      } catch (err) {
        if (err instanceof Error) {
          console.log(err.message)
        }
      }
    }
  }

  const onEndedMovie = () => {
    if (movie?.nextPlayMovieId) {
      navigate(`/movie/${movie?.nextPlayMovieId}`)
    }
  }

  const setMovieProgress = async (state: PlayProgress) => {
    //再生経過率の値を取得
    const rate = Math.round(state.played * 100)
    //再生経過率を5単位で丸める
    const rateFive = (Math.round((rate * 2) / 10) * 10) / 2
    //5ずつ更新
    if (playProgressRate !== rateFive) {
      setPlayProgressRate(rateFive)
      //ここで再生率データを登録
      if (movie) {
        try {
          const json: any = await setMoviePlayDataDb(movie.id, playProgressRate)
          console.log(json)
        } catch (err) {
          if (err instanceof Error) {
            console.log(err.message)
          }
        }
      }
      console.log(playProgressRate)
    }
  }

  if (!movie) {
    return <div className="w-100 text-center"></div>
  }

  return (
    <>
      {!checkMovieAccess({
        categoryStatus: movie?.categoryStatus,
        userLicenseId: auth.currentUser?.licenseId,
        movieLicenses: movie?.licenseIds,
      }) && (
        <Col md={8} className="ps-md-0">
          <div className="thumbnail-licence-player">
            <div className="licence">
              <div className="licence-lock-wrap">
                <BsFillLockFill size={40} className="lock-icon mb-3" />
                <br />
                ライセンスが必要です
              </div>
            </div>
            <div className="thumbnail-content-player">
              <SelectThumbnail movie={movie} />
            </div>
          </div>
          <h5 className="mt-2 mb-3">
            {movie?.categoryStatus && +movie?.categoryStatus === 2 && (
              <>
                <span className="badge bg-secondary me-2">ゲスト公開</span>
              </>
            )}
            {movie?.name}
            <span className="ms-1 small">（{movie.playTime}）</span>
          </h5>
          <div className="small text-secondary">
            <span className="me-1"> {movie.createDate}</span>投稿・<span className="me-1">{movie?.playCount}</span>
            回視聴
          </div>
          <p className="mt-3">{movie?.comment}</p>
        </Col>
      )}
      {checkMovieAccess({
        categoryStatus: movie?.categoryStatus,
        userLicenseId: auth.currentUser?.licenseId,
        movieLicenses: movie.licenseIds,
      }) && (
        <Col md={8} className="ps-md-0">
          <div className="contents-box">
            <div className="player-wrapper">
              <ReactPlayer
                width="100%"
                height="100%"
                ref={player}
                url={`https://${process.env.REACT_APP_DOMAIN}/${movie.hlsFile}.m3u8`}
                className="react-player"
                light={`https://${process.env.REACT_APP_DOMAIN}/${movie?.hlsFile?.replace(
                  "movies",
                  "images"
                )}_thumbnail.0000000.jpg`}
                controls={true}
                playing={true}
                volume={0.5}
                muted={false}
                playsinline={true}
                progressInterval={1000}
                onStart={onStartedMovie}
                onProgress={setMovieProgress}
                onEnded={onEndedMovie}
              />
            </div>
            <h5 className="mt-2 mb-3">
              {movie?.categoryStatus && +movie?.categoryStatus === 2 && (
                <>
                  <span className="badge bg-secondary me-2">ゲスト公開</span>
                </>
              )}
              {movie?.name}
              <span className="ms-1 small">（{movie.playTime}）</span>
            </h5>
            <div className="small text-secondary">
              <span className="me-1"> {movie.createDate}</span>投稿・<span className="me-1">{movie?.playCount}</span>
              回視聴
            </div>
            <p className="mt-3">{movie?.comment}</p>
          </div>
        </Col>
      )}

      <Col md={4} className="pe-md-0">
        <ShowCategoryMovies currentMovieId={movie?.id} categoryId={movie?.categoryId} playerPage={true} />
      </Col>
      <ShowChildCategories parentId={movie?.categoryId} />
    </>
  )
}
