import React from "react"
import { Button } from "react-bootstrap"

type Step = {
  step: string
  image: string
  text: string
  link: string
  buttonText: string
}

export const RegisterStep = (props: Step) => {
  const step = props.step
  const image = props.image
  const text = props.text
  const link = props.link
  const buttonText = props.buttonText
  return (
    <div className="register-step d-grid col-md-8 position-relative border border-dark rounded mx-auto mb-4 p-4">
      <div className="d-inline-block w-auto">
        <h4 className="fw-bold mb-4">
          <span className="badge text-bg-dark">STEP{step}</span>
        </h4>
        <figure className="mb-5 pb-5">
          <img src={image} alt="" className="d-block mb-3 mx-auto" width="auto" />
          <figcaption className="text-center">{text}</figcaption>
        </figure>
        <div className="position-absolute bottom-0 start-50 translate-middle-x d-grid col-6 text-center mx-auto mb-4">
          <Button className="rounded-pill fw-bold p-2" variant="dark" href={link}>
            {buttonText}
          </Button>
        </div>
      </div>
    </div>
  )
}
