import React from "react"
import { Button, Navbar } from "react-bootstrap"

export const SignupLink = () => {
  return (
    <>
      <Navbar.Text>
        <Button className="me-lg-3" variant="light" href="https://www.kaihipay.jp/forms?form_code=5909146335907280">
          サインアップ
        </Button>
      </Navbar.Text>
    </>
  )
}
